import type {
  HeadlineResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  OrgCompositeRankingResRelatedRankingResponseRelatedPagedData,
  PlayerItemResponsePagedData,
  SiteResponse,
  TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData,
  TrendingListItemResponseTrendingListResponseRelatedPagedData,
} from '@on3/api';
import { ServiceLogo } from '@on3/ui-lib/components/ServiceLogo/ServiceLogo';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { TopRankings as CollegeRankings } from 'components/College/Modules/TopRankings/TopRankings';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { TopRankings as HighSchoolRankings } from 'components/HighSchool/Modules/TopRankings/TopRankings';
import { SEOHomeText } from 'components/Home/SEOHomeText';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { SignUp } from 'components/Newsletter/SignUp';
import { SignUpModule } from 'components/Newsletter/SignUpModule';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { Connect } from 'components/Social/Connect';
import { FanSiteList } from 'components/Teams/FanSiteList';
import { LatestCommits } from 'components/Teams/LatestCommits/LatestCommits';
import { TopHeadlines } from 'components/TopHeadlines';
import { on3Data } from 'store/mocks/Connect.mock';
import { useAds } from 'utils/hooks/useAds';

import { removeDuplicateArticles } from './articleHelper';
import styles from './NetworkHome.module.scss';

export interface INetworkPageProps {
  featuredArticles: HeadlineResponse[] | null;
  collegeSportsArticles: HeadlineResponse[] | null;
  recruitingArticles: HeadlineResponse[] | null;
  collegeBusinessArticles: HeadlineResponse[] | null;
  nilHundred: NilHundredResponseNil100FilterResponseRelatedPagedData | null;
  latestCommits: PlayerItemResponsePagedData | null;
  hsArticles: HeadlineResponse[] | null;
  highSchoolRankings: OrgCompositeRankingResRelatedRankingResponseRelatedPagedData | null;
  collegeRankings: TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData | null;
  trendingList: TrendingListItemResponseTrendingListResponseRelatedPagedData | null;
  fanSites: SiteResponse[] | null;
}

const sportViewAll = [
  {
    url: 'category/football/news/',
    text: 'View All Football',
    title: 'Football News',
  },
  {
    url: 'category/basketball/news/',
    text: 'View All Basketball',
    title: 'Basketball News',
  },
];

const recruitingViewAll = [
  {
    url: 'category/football-recruiting/news/',
    text: 'View All Football Recruiting',
    title: 'Football Recruiting News',
  },
  {
    url: 'category/basketball-recruiting/news/',
    text: 'View All Basketball Recruiting',
    title: 'Basketball Recruiting News',
  },
];

const businessViewAll = [
  {
    url: 'nil/news/',
    text: 'View All NIL',
    title: 'NIL News',
  },
];
const hsViewAll = [
  {
    url: 'high-school/news/',
    text: 'View All High School News',
    title: 'High School News',
  },
];

export const NetworkHome = ({
  featuredArticles,
  collegeSportsArticles,
  recruitingArticles,
  collegeBusinessArticles,
  nilHundred,
  latestCommits,
  highSchoolRankings,
  collegeRankings,
  hsArticles,
  trendingList,
  fanSites,
}: INetworkPageProps) => {
  const teamSites = fanSites?.filter((site) => site.live && site.inIndex);
  const { list } = nilHundred || {};

  const coverArticles = featuredArticles?.slice(0, 3);
  const coverKeys = coverArticles?.map((article) => article.key);

  const collegeSports = removeDuplicateArticles({
    list: collegeSportsArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  const recruiting = removeDuplicateArticles({
    list: recruitingArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  const collegeBusiness = removeDuplicateArticles({
    list: collegeBusinessArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  useAds({ pageType: 'home' });

  return (
    <div className={styles.layout}>
      <SEOHomeText className={styles.headline}>
        <ServiceLogo type="on3" />
        <h1>
          Delivering trusted college and high school sports news, analysis,
          data, and insights to fans, athletes, schools, and brands
        </h1>
      </SEOHomeText>
      <HomeLayout>
        <div className={styles.center}>
          <div className={styles.inner}>
            {!!coverArticles?.length && (
              <div className={styles.coverArticles}>
                <CoverList
                  authorColor="#6F747B"
                  backgroundColor="transparent"
                  isHome
                  list={coverArticles}
                  titleColor="#1D252C"
                />
              </div>
            )}

            {!!trendingList && (
              <div className={styles.trending}>
                <TopHeadlines data={trendingList} />
                <SignUpModule title="Get the On3 Top 10 to your inbox every morning" />
              </div>
            )}

            {!!collegeSports?.length && (
              <FeedList
                list={collegeSports.slice(0, 5)}
                section="College Sports"
                viewAllLinks={sportViewAll}
              />
            )}

            <MobileMPU id="mobile_mpu_top" incontent />

            {!!recruiting?.length && (
              <FeedList
                list={recruiting.slice(0, 5)}
                section="Recruiting"
                viewAllLinks={recruitingViewAll}
              />
            )}

            {!!collegeBusiness?.length && (
              <FeedList
                list={collegeBusiness.slice(0, 5)}
                section="NIL"
                viewAllLinks={businessViewAll}
              />
            )}

            {!!hsArticles?.length && (
              <FeedList
                list={hsArticles.slice(0, 5)}
                section="High School"
                viewAllLinks={hsViewAll}
              />
            )}
          </div>
        </div>

        <aside className={styles.right}>
          <div className={styles.inner}>
            <MPU id="mpu_top" />

            {!!teamSites && <FanSiteList list={teamSites} />}

            {latestCommits?.list?.length && (
              <LatestCommits list={latestCommits?.list} />
            )}

            {collegeRankings && <CollegeRankings rankings={collegeRankings} />}

            <MPU className={styles.sticky} id="mpu_middle" />

            <MobileMPU id="mobile_mpu_middle" incontent />
          </div>
        </aside>

        <aside className={styles.left}>
          <div className={styles.inner}>
            {!!trendingList && (
              <div className={styles.trending}>
                <TopHeadlines data={trendingList} />
                <SignUpModule title="Get the On3 Top 10 to your inbox every morning" />
              </div>
            )}

            {highSchoolRankings && (
              <HighSchoolRankings rankings={highSchoolRankings} />
            )}

            {!!list?.length && <Nil100Widget list={list?.slice(0, 5)} />}

            {!!on3Data && <Connect list={on3Data} title="On3 Connect" />}
          </div>
        </aside>
      </HomeLayout>
      <SignUp />
    </div>
  );
};
