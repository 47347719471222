import * as yup from 'yup';

type ErrorsType = {
  [key: string]: string;
};

const getErrors = (error: yup.ValidationError) => {
  return error.inner.reduce((acc, err) => {
    if (err.path) (acc as any)[err.path] = err.message;

    return acc;
  }, {});
};

export { type ErrorsType, getErrors, yup };
