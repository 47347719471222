import {
  CategoryTypes,
  OrganizationType,
  rdbV1TrendingListsList,
  rdbV2LatestList,
  rdbV2Nil100List,
  RdbV2Nil100ListParams,
  SiteType,
  v1CategoriesFeaturedHeadlinesDetail,
  v1CategoriesHeadlinesDetail2,
  v1OrganizationCompositeRankingsList,
  v1SitesHeadlinesForCategoryTypeDetail,
  v1SitesList,
  type V1SitesListParams,
  v1TeamRankingsRankingsList,
} from '@on3/api';
import { withAuthentication } from '@on3/ui-lib/src/hocs/withAuthentication';
import { withValidUrl } from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import { INetworkPageProps, NetworkHome } from 'components/Home/NetworkHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';
import { GraphSchema } from 'store/schemas/Graph';
import { OrganizationSchema } from 'store/schemas/Organization';

const title = 'On3.com | The Best of College and High School Sports';
const description = `On3 is the best resource for college and high school sports, recruiting, and NIL. Find all of your favorite team's information or browse our advanced player database.`;

const NetworkHomePage = (props: INetworkPageProps) => {
  const canonical = 'https://www.on3.com/';

  const schema = new GraphSchema({
    items: [new OrganizationSchema().toJSON()],
  });

  return (
    <PageWrapper
      {...props}
      canonical={canonical}
      description={description}
      schema={schema}
      title={title}
    >
      <NetworkHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  try {
    const year =
      siteData?.defaultYears?.footballSeasonYear || new Date().getFullYear();

    // Calling in 8 since we remove duplicates from carousel
    const collegeHeadlinesParams = {
      type: CategoryTypes.Sport,
      siteKey: 44,
      limit: 8,
    };
    const recHeadlinesParams = {
      type: CategoryTypes.Recruiting,
      siteKey: 44,
      limit: 8,
    };

    const collegeBusinessHeadlinesParams = {
      category: 130,
      limit: 8,
    };
    const highSchoolHeadlinesParams = {
      category: 556984,
      limit: 8,
    };
    const timeOut = {
      timeout: 2000,
    };

    const rankingsParams = {
      sportKey: 1,
      year,
      limit: 10,
    };

    const fanSiteParams: V1SitesListParams = {
      type: SiteType.Team,
    };

    const [
      featuredArticles,
      collegeSportsArticles,
      recruitingArticles,
      collegeBusinessArticles,
      nilHundred,
      latestCommits,
      hsArticles,
      highSchoolRankings,
      collegeRankings,
      trendingList,
      fanSites,
    ] = await Promise.allSettled([
      v1CategoriesFeaturedHeadlinesDetail(proxyApi, 8, timeOut),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, collegeHeadlinesParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, recHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, collegeBusinessHeadlinesParams),
      rdbV2Nil100List(proxyApi, {
        limit: 5,
      }),
      rdbV2LatestList(proxyApi, {
        limit: 5,
      }),
      v1CategoriesHeadlinesDetail2(proxyApi, highSchoolHeadlinesParams),
      v1OrganizationCompositeRankingsList(proxyApi, {
        orgType: OrganizationType.HighSchool,
        items: 10,
        sportKey: 1,
        year,
      }),
      v1TeamRankingsRankingsList(proxyApi, {
        ...rankingsParams,
        orgType: OrganizationType.College,
      }),
      rdbV1TrendingListsList(proxyApi, {
        trendingListKey: 1,
        siteKey: 44,
        limit: 10,
      }),
      v1SitesList(proxyApi, fanSiteParams),
    ]);

    return {
      props: {
        pageType: 'home',
        siteData: siteData || [],
        userData: userData || {},
        featuredArticles: getFulfilledValue(featuredArticles),
        collegeSportsArticles: getFulfilledValue(collegeSportsArticles),
        recruitingArticles: getFulfilledValue(recruitingArticles),
        collegeBusinessArticles: getFulfilledValue(collegeBusinessArticles),
        nilHundred: getFulfilledValue(nilHundred),
        latestCommits: getFulfilledValue(latestCommits),
        hsArticles: getFulfilledValue(hsArticles),
        highSchoolRankings: getFulfilledValue(highSchoolRankings),
        collegeRankings: getFulfilledValue(collegeRankings),
        trendingList: getFulfilledValue(trendingList),
        fanSites: getFulfilledValue(fanSites),
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err.message);

    return { props: {} };
  }
};

export default withValidUrl(NetworkHomePage);
