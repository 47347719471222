import clsx from 'clsx';
import React, { FC, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import styles from './Input.module.scss';

interface InputProps {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  srLabel?: boolean;
  error?: FieldError | string;
  placeholder?: string;
  value?: string | number | null;
  prefix?: React.ReactNode | string;
  suffix?: React.ReactNode | string;
  helperText?: string;
  [key: string]: any;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      label,
      srLabel = false,
      error,
      placeholder = '',
      value,
      prefix,
      suffix,
      helperText,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={styles.base} data-ui="input">
        <label>
          <span
            className={clsx(styles.label, {
              'sr-only': srLabel,
            })}
          >
            {label}
          </span>
          <div className={styles.wrapper}>
            {prefix && (
              <div className={styles.prefix} data-ui="input-prefix">
                {prefix}
              </div>
            )}
            <input
              onChange={onChange}
              placeholder={placeholder}
              ref={ref}
              type="text"
              value={value || ''}
              {...props}
            />
            {suffix && <div className={styles.suffix}>{suffix}</div>}
          </div>
        </label>
        {helperText && <p className={styles.helper}>{helperText}</p>}
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export { Input };
